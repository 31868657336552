/* vietnamese */
@font-face {
  font-family: '__Crimson_Text_43d354';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b23db1c6f4ff0acf-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Crimson_Text_43d354';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9cb252b81356e010-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Crimson_Text_43d354';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e400baae650c21fc-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Crimson_Text_43d354';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/52c4e967c8df92fc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Crimson_Text_43d354';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fbb17e27025aba06-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Crimson_Text_43d354';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/72b894ddc38a7cc1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Crimson_Text_Fallback_43d354';src: local("Times New Roman");ascent-override: 97.42%;descent-override: 35.98%;line-gap-override: 0.00%;size-adjust: 97.44%
}.__className_43d354 {font-family: '__Crimson_Text_43d354', '__Crimson_Text_Fallback_43d354';font-style: normal
}.__variable_43d354 {--font-crimson: '__Crimson_Text_43d354', '__Crimson_Text_Fallback_43d354'
}

/* arabic */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EFD-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_725860';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Rubik_Fallback_725860';src: local("Arial");ascent-override: 88.57%;descent-override: 23.68%;line-gap-override: 0.00%;size-adjust: 105.57%
}.__className_725860 {font-family: '__Rubik_725860', '__Rubik_Fallback_725860';font-style: normal
}.__variable_725860 {--font-rubik: '__Rubik_725860', '__Rubik_Fallback_725860'
}

/* latin-ext */
@font-face {
  font-family: '__DM_Serif_Display_fb0d26';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9a5f1275b7cedad-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Serif_Display_fb0d26';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8679c800f1e60000-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Serif_Display_Fallback_fb0d26';src: local("Times New Roman");ascent-override: 93.52%;descent-override: 30.24%;line-gap-override: 0.00%;size-adjust: 110.78%
}.__className_fb0d26 {font-family: '__DM_Serif_Display_fb0d26', '__DM_Serif_Display_Fallback_fb0d26';font-weight: 400;font-style: normal
}.__variable_fb0d26 {--font-DMSerifDisplay: '__DM_Serif_Display_fb0d26', '__DM_Serif_Display_Fallback_fb0d26'
}

